import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MultiSelect } from 'react-multi-select-component';
import axios from 'axios';
import dayjs from 'dayjs';
import { isValidDate, calculateGranularity } from '../utilities/utilities'
import { SOURCES_FACTUALITY_LEVEL, SOURCES_HARMFULNESS_LEVEL } from '../utilities/consts'
// factuality/harmfulness select
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';

// slider
// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import Slider from '@mui/material/Slider';


export default function FilteringPostsComponent({ allStates, setAllStates }) {

    // TODO: on enter button filter
    // useEffect(() => {
    //     const keyDownHandler = event => {
    //         if (event.key === 'Enter') {
    //             event.preventDefault();
    //             filterPosts(event);
    //         }
    //     };
    //     handlePostsRefresh();
    //     document.addEventListener('keydown', keyDownHandler);
    //     return () => {
    //         document.removeEventListener('keydown', keyDownHandler);
    //     };
    // }, []);

    // factuality slider
    const handleFactualityValue = (event, newValue) => {
        setAllStates({ ...allStates, 'factualityValue': newValue })
    };

    // harmful slider
    const handleHarmfulValue = (event, newValue) => {
        setAllStates({ ...allStates, 'harmfulValue': newValue })
    };

    const handleSelectedSources = (values) => {
        setAllStates({ ...allStates, 'selectedValues': values })
    };

    // filtering
    async function filterPosts(e) {
        if (Object.keys(allStates.selectedValues).length) {
            setAllStates({ ...allStates, 'posts': null })
            const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : '';
            const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : '';
            axios.post(process.env.REACT_APP_API_CONTENT_SEARCH, {
                and_filters: {
                    orig_created_at_from: created_at_from ? created_at_from : null,
                    orig_created_at_to: created_at_to ? created_at_to : null,
                },
                source_group_links: allStates.selectedValues.map(source => source.label),
                body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
                statistics: {
                    name: "checkworthiness",
                    granularity: calculateGranularity(created_at_from, created_at_to),
                    checkworthiness_indicator: {
                        factual_1: allStates.factualityValue,
                        harmful_1: allStates.harmfulValue
                    },
                    params: {
                        factual_1: allStates.factualityValue,
                        harmful_1: allStates.harmfulValue
                    }
                }
            }).then((first_response) => {
                return first_response.data.statistics
            }).then(async (statistics) => {
                axios.post(process.env.REACT_APP_API_CONTENT_SEARCH, {
                    and_filters: {
                        orig_created_at_from: created_at_from ? created_at_from : null,
                        orig_created_at_to: created_at_to ? created_at_to : null,
                    },
                    source_group_links: allStates.selectedValues.map(source => source.label),
                    body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
                    entities: ["annotations"],
                    annotation: {
                        annotation_name: "Document check-worthiness",
                        params: {
                            factual_1_from: allStates.factualityValue,
                            harmful_1_from: allStates.harmfulValue
                        }
                    },
                    page: 1
                }).then((response) => {
                    // TODO: change after creation of ContentStatistics response on BE
                    statistics.forEach(stat => {
                        stat.date = stat.date.slice(0, 10)
                    });
                    setAllStates({
                        ...allStates,
                        'posts': response.data.contents,
                        'postsPage': 1,
                        'totalPosts': response.data.pagination.total,
                        'numberOfPostPages': response.data.pagination.pages,
                        'postStatistics': statistics,
                        'postOrderBy': 'orig_created_at',
                        'postOrderType': 'desc',
                        'selectedPageIndex': 1
                    })
                })
            })
        }
    }

    // reset button
    const resetFilters = (e) => {
        window.location.reload();
        setAllStates({
            ...allStates,
            'bodyFilter': '',
            'startDate': null,
            'endDate': null,
            'selectedValues': JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.id, "label": x.link })),
            'factualityValue': SOURCES_FACTUALITY_LEVEL,
            'harmfulValue': SOURCES_HARMFULNESS_LEVEL
        })
    }

    // TODO: check this potential refactor to already implemented MUI selector
    const customValueRenderer = (selected, _options) => {
        return selected.length <= 5 ? (
            selected.map(({ label, index }) => <div labelId={label} className="bg-primary d-inline mx-1 p-2">{label}</div>)
        ) : selected.length > 5 ? (
            <div>
                {selected.slice(0, 5).map(({ label, index }) => <div labelId={label} className="bg-primary d-inline mx-1 p-2" key={index}>{label}</div>)}
                {<div className="bg-primary d-inline mx-1 p-2">...</div>}
            </div>
        ) : null
    };
    return (
        <div>
            <div className='row'>
                <MultiSelect
                    options={JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.link, "label": x.link }))}
                    value={allStates.selectedValues}
                    onChange={handleSelectedSources}
                    valueRenderer={customValueRenderer}
                />
            </div>
            <div className='row'>
                <div className="col-2 align-self-center mt-4">
                    <TextField value={allStates.bodyFilter} onChange={e => setAllStates({ ...allStates, 'bodyFilter': e.target.value })} fullWidth id="filled-basic" label="Filter on posts body" variant="filled" />
                </div>
                <div className="col-auto align-self-center mt-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker value={allStates.startDate} format="YYYY/MM/DD" clearable onChange={e => setAllStates({ ...allStates, 'startDate': e })} label="Date from" />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="col-auto align-self-center mt-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker value={allStates.endDate} format="YYYY/MM/DD" clearable onChange={e => setAllStates({ ...allStates, 'endDate': e })} label="Date to" />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>

                {/* TODO: Refactor ids, htmFor, etc */}
                <div className="col-auto align-self-center mt-3">
                    <FormControl sx={{ width: 100, justifyContent: 'center' }}>
                        <FormLabel id="select-field-demo-label" htmlFor="select-field-demo-button">
                            Factuality
                        </FormLabel>
                        <Select
                            onChange={handleFactualityValue}
                            value={allStates.factualityValue}
                            slotProps={{
                                button: {
                                    id: 'select-field-demo-button',
                                    // TODO: Material UI set aria-labelledby correctly & automatically
                                    // but Base UI and Joy UI don't yet.
                                    'aria-labelledby': 'select-field-demo-label select-field-demo-button',
                                },
                            }}
                        >   {/* TODO: render this in a map */}
                            <Option value={0.9}>Top 10%</Option>
                            <Option value={0.8}>Top 20%</Option>
                            <Option value={0.7}>Top 30%</Option>
                            <Option value={0.6}>Top 40%</Option>
                            <Option value={0.5}>Top 50%</Option>
                            <Option value={0.4}>Top 60%</Option>
                            <Option value={0.3}>Top 70%</Option>
                            <Option value={0.2}>Top 80%</Option>
                            <Option value={0.1}>Top 90%</Option>
                            <Option value={0.0}>Top 100%</Option>
                        </Select>
                    </FormControl>
                    {/* <Box sx={{ width: 160, border: 1, borderColor: 'text.primary', paddingLeft: 2, paddingRight: 2 }}>
                        <Stack direction="row" alignItems="center">
                            <Slider value={allStates.factualityValue} aria-label="Disabled slider" onChange={handleFactualityValue} />
                        </Stack>
                        <Stack alignItems="center">Factuality: {allStates.factualityValue > 0 ? allStates.factualityValue : "disabled"}</Stack>
                    </Box> */}
                </div>
                <div className='col-auto align-self-center mt-3'>
                    <FormControl sx={{ width: 100, justifyContent: 'center' }}>
                        <FormLabel id="select-field-demo-label" htmlFor="select-field-demo-button">
                            Harmfulness
                        </FormLabel>
                        <Select
                            onChange={handleHarmfulValue}
                            value={allStates.harmfulValue}
                            slotProps={{
                                button: {
                                    id: 'select-field-demo-button',
                                    // TODO: Material UI set aria-labelledby correctly & automatically
                                    // but Base UI and Joy UI don't yet.
                                    'aria-labelledby': 'select-field-demo-label select-field-demo-button',
                                },
                            }}
                        >
                            {/* TODO: render this in a map */}
                            <Option value={0.9}>Top 10%</Option>
                            <Option value={0.8}>Top 20%</Option>
                            <Option value={0.7}>Top 30%</Option>
                            <Option value={0.6}>Top 40%</Option>
                            <Option value={0.5}>Top 50%</Option>
                            <Option value={0.4}>Top 60%</Option>
                            <Option value={0.3}>Top 70%</Option>
                            <Option value={0.2}>Top 80%</Option>
                            <Option value={0.1}>Top 90%</Option>
                            <Option value={0.0}>Top 100%</Option>
                        </Select>
                    </FormControl>
                    {/* <Box sx={{ width: 160, border: 1, borderColor: 'text.primary', paddingLeft: 2, paddingRight: 2 }}>
                        <Stack direction="row" alignItems="center">
                            <Slider value={allStates.harmfulValue} aria-label="Disabled slider" onChange={handleHarmfulValue} />
                        </Stack>
                        <Stack alignItems="center">Harmful: {allStates.harmfulValue > 0 ? allStates.harmfulValue : "disabled"}</Stack>
                    </Box> */}
                </div>
                <div className="col-auto align-self-center justify-content-end mt-4">
                    <Button variant="contained" onClick={filterPosts}>Filter</Button>
                </div>
                <div className="col-auto align-self-center justify-content-end mt-4">
                    <Button variant="contained" onClick={resetFilters}>Reset Filter</Button>
                </div>
            </div>
        </div>
    );
}