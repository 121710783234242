import { Icon, styled } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import moment from 'moment';

export function normalize(val, min, max) {
    var delta = max - min;
    return (val - min) / delta;
}

  /* TODO: move this to some helper file? */
 export const ElementIconLink = styled(Icon)(({ theme }) => ({
    '&:hover': { color: 'red' },
  }))

 export const StyledContentTableCell = styled(TableCell)({
    fontSize: 14
  });

  export const isValidDate = (dateObject) => new Date(dateObject).toString() !== 'Invalid Date' && dateObject !== null;

  export function calculateGranularity(startDate, endDate) {
    const start = isValidDate(startDate) === true ? moment(startDate) : moment("1970-01-01 00:00:00");
    const end = isValidDate(endDate) === true ? moment(endDate) : moment();
    const granularity = end.diff(start, "months")
    if (granularity < 2)
      return "day"
    else if(granularity < 12)
      return "week"
    else return "month"
  }