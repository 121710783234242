import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const HoverInfo = React.forwardRef((props, title) => (
  <Tooltip title={title} componentsProps={{
    tooltip: {
      sx: {
        bgcolor: 'common.black',
        fontSize: 14,
      },
    },
  }}{...props}>
  <IconButton>
      <InfoIcon/>
  </IconButton>
  </Tooltip>
));


export default HoverInfo;