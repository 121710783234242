import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import cedmo from './cedmo_logo.png';
import { DRAWER_WIDTH } from '../utilities/consts';

export default function HeaderBar({ open, handleDrawerOpen }) {
    const navigate = useNavigate();
    const signOut = () => {
        localStorage.removeItem("username");
        navigate("/");
    };
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            marginLeft: `${DRAWER_WIDTH}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));
    return (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    onClick={handleDrawerOpen}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <img src={cedmo} alt="logo" style={{width: '140px', height: 'auto', margin: 10}}/>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 5 }}>
                    Checkworthy document detection app
                </Typography>
                <Button className='btn-outline-light bg-secondary text-dark' onClick={signOut}>Sign out</Button>
            </Toolbar>
        </AppBar>
    );
}