import { useLocation, Navigate } from "react-router-dom"

export const setToken = (username, token, user_id)=>{
    
    localStorage.setItem("username", username)
    localStorage.setItem("token", token)
    localStorage.setItem("user_id", user_id)
}

export const fetchToken = ()=>{
    return localStorage.getItem("username")
}

export function RequireToken({children}){
    
    let auth = fetchToken()
    let location = useLocation()

    if(!auth){
        return <Navigate to='/' state ={{from : location}}/>;
    }

    return children;
}