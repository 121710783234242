import { fetchToken } from "../components/Auth";
import React, { useState, useEffect } from "react";
import Login from "../components/Login";
import { useNavigate } from "react-router";
import axios from "axios";


export default function LoginPage({ allStates, setAllStates }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    return ( 
        <div style={{ marginTop: 30 }}>
                <section className="vh-100 primary">
                    <div className="container py-5 h-100 text-white">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                                <div className="card bg-primary rounded">
                                    <div>
                                        <ul className="bg-white nav nav-tabs" role="tablist" id="myTabs">
                                            <li className="active settingshead">
                                                <a data-toggle="tab" href="#login">Login</a>
                                            </li>
                                            {/* USER REGISTRATION: Temporary disabled */}
                                            {/* <li className="settingshead">
                    <a data-toggle="tab" href="#register">Register</a>
                </li> */}
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane fade in active" id="login">
                                            <Login allStates={allStates} setAllStates={setAllStates} 
                                            email={email} password={password} setPassword={setPassword} setEmail={setEmail}/>
                                        </div>
                                        {/* USER REGISTRATION: Temporary disabled */}
                                        {/* <div role="tabpanel" className="tab-pane fade" id="register">
                <p>Register</p>
            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
    );
}