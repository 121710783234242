export const SOURCES_FACTUALITY_LEVEL = 0.5
export const SOURCES_HARMFULNESS_LEVEL = 0.2
export const DRAWER_WIDTH = 240;
export const POSTS_BODY_MAX_CHARS = 500;
export const harmfulBins = {
  '1': {color: "#FF3333ff", text: 'Top 10%'},
  '0.9': {color: "#FF3333ff", text: 'Top 10%'},
  '0.8': {color: "#FF4444ff", text: 'Top 20%'},
  '0.7': {color: "#FF5555ff", text: 'Top 30%'},
  '0.6': {color: "#FF6666ff", text: 'Top 40%'},
  '0.5': {color: "#FF7777ff", text: 'Top 50%'},
  '0.4': {color: "#FF8989ff", text: 'Top 60%'},
  '0.3': {color: "#FF9A9Aff", text: 'Top 70%'},
  '0.2': {color: "#FFABABff", text: 'Top 80%'},
  '0.1': {color: "#FFBCBCff", text: 'Top 90%'},
  '0': {color: "#FFCDCDff", text: 'Top 100%'},
}

export const factualityBins = {
  '1': {color: "#000DFFff", text: 'Top 10%'},
  '0.9': {color: "#000DFFff", text: 'Top 10%'},
  '0.8': {color: "#1824FFff", text: 'Top 20%'},
  '0.7': {color: "#313BFFff", text: 'Top 30%'},
  '0.6': {color: "#4951FFff", text: 'Top 40%'}, 
  '0.5': {color: "#6268FFff", text: 'Top 50%'}, 
  '0.4': {color: "#7A7FFFff", text: 'Top 60%'},
  '0.3': {color: "#9396FFff", text: 'Top 70%'},
  '0.2': {color: "#ABACFFff", text: 'Top 80%'}, 
  '0.1': {color: "#C4C3FFff", text: 'Top 90%'},
  '0': {color: "#DCDAFFff", text: 'Top 100%'},
}