import React, { useState, useEffect } from 'react';
import HeaderBar from '../components/HeaderBar';
import DrawerComponent from '../components/DrawerComponent';
import FilteringPostsComponent from '../components/FilteringPostsComponent';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Main from '../components/Main';
import DrawerHeader from '../components/DrawerHeader';
import axios from 'axios';
import dayjs from 'dayjs';

// utilities
import { ElementIconLink, StyledContentTableCell, isValidDate, calculateGranularity } from '../utilities/utilities'
import { POSTS_BODY_MAX_CHARS, DRAWER_WIDTH, factualityBins, harmfulBins, SOURCES_FACTUALITY_LEVEL, SOURCES_HARMFULNESS_LEVEL } from '../utilities/consts';

// posts formatting
import Table from '@mui/joy/Table';
import Chip from '@mui/material/Chip';
import LinkIcon from '@mui/icons-material/Link';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

// pagination
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// charts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// fetching data 
import CircularProgress from "@mui/material/CircularProgress";
// sort button
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormLabel from '@mui/joy/FormLabel';

export default function Posts({
  allStates, setAllStates,
  theme, open, setOpen, handleDrawerClose, handleDrawerOpen
}) {

  function PostBodyComponent({body}) {
    const [showMore, setShowMore] = useState(false);  
    return (
      <StyledContentTableCell sx={{ width: '40%' }}>
      {showMore ? body : body.length > POSTS_BODY_MAX_CHARS ? body.substring(0,POSTS_BODY_MAX_CHARS)+"..." : body}
      {body.length > POSTS_BODY_MAX_CHARS ?
      <Button sx={{ m: 1 } }size="small" variant="contained" onClick={() => setShowMore(!showMore)}>
        {showMore ? "Show Less" : "Show More"}
      </Button> : ""
      }
      </StyledContentTableCell>
    )
  }

  // on reload or loading page
  useEffect(() => {
    setAllStates({...allStates, 'posts': null})
    const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : '';
    const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : '';
    axios.post(process.env.REACT_APP_API_CONTENT_SEARCH, {
      and_filters: {
        orig_created_at_from: created_at_from ? created_at_from : null,
        orig_created_at_to: created_at_to ? created_at_to : null,
      },
      body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
      source_group_links: JSON.parse(localStorage.getItem("loadInitPosts")) ? JSON.parse(localStorage.getItem("sources")).map(x => x.link ) : allStates.selectedValues.map(source => source.label),
      statistics: {
        name: "checkworthiness",
        granularity: calculateGranularity(created_at_from, created_at_to),
        checkworthiness_indicator: {
          factual_1: allStates.factualityValue,
          harmful_1: allStates.harmfulValue
        },
        params: {
          factual_1: allStates.factualityValue,
          harmful_1: allStates.harmfulValue
        }
      }
    }).then((first_response) => {
      return first_response.data.statistics
    }).then(async (statistics) => {
      axios.post(process.env.REACT_APP_API_CONTENT_SEARCH, {
        and_filters: {
          orig_created_at_from: created_at_from ? created_at_from : null,
          orig_created_at_to: created_at_to ? created_at_to : null,
        },
        body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
        source_group_links: JSON.parse(localStorage.getItem("loadInitPosts")) ? JSON.parse(localStorage.getItem("sources")).map(x => x.link ) : allStates.selectedValues.map(source => source.label),
        entities: ["annotations"],
        annotation: {
          annotation_name: "Document check-worthiness",
          params: {
            factual_1_from: allStates.factualityValue,
            harmful_1_from: allStates.harmfulValue
          }
        },
        page: 1
      }).then((response) => {
        // TODO: change after creation of ContentStatistics response on BE
        statistics.forEach(stat => {
          stat.date = stat.date.slice(0, 10)
        });
        setAllStates({
          ...allStates,
          'posts': response.data.contents,
          'postsPage': 1,
          'totalPosts': response.data.pagination.total,
          'numberOfPostPages': response.data.pagination.pages,
          'postStatistics': statistics,
          // TODO: change this when URL params will be implemented
          'selectedValues': JSON.parse(localStorage.getItem("loadInitPosts")) ? JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.link, "label": x.link })) : allStates.selectedValues,
          'selectedPageIndex': 1
        })
        localStorage.setItem("loadInitPosts", true)
      })
    })
  }, []);

  const handleSort = (event, newValue) => {
    setAllStates({ ...allStates, 'posts': null })
    const [orderBy, orderType] = newValue.split("-")
    const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : '';
    const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : '';
    axios.post(process.env.REACT_APP_API_CONTENT_SEARCH, {
      and_filters: {
        orig_created_at_from: created_at_from ? created_at_from : null,
        orig_created_at_to: created_at_to ? created_at_to : null,
      },
      source_group_links: allStates.selectedValues.map(source => source.label),
      body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
      annotation: {
        annotation_name: "Document check-worthiness",
        params: {
          factual_1_from: allStates.factualityValue,
          harmful_1_from: allStates.harmfulValue
        }
      },
      entities: ["annotations"],
      order_by: orderBy,
      order_type: orderType,
      page: 1
    })
      .then((response) => {
        setAllStates({
          ...allStates,
          'posts': response.data.contents,
          'totalPosts': response.data.pagination.total,
          'postsPage': 1,
          'postOrderBy': orderBy,
          'postOrderType': orderType
        })
      });
  };

  const handleChangePage = (event, newPage) => {
    setAllStates({ ...allStates, 'posts': null })
    const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : '';
    const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).format("YYYY-MM-DD HH:mm:ss") : '';
    axios.post(process.env.REACT_APP_API_CONTENT_SEARCH, {
      and_filters: {
        orig_created_at_from: created_at_from ? created_at_from : null,
        orig_created_at_to: created_at_to ? created_at_to : null,
      },
      body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
      source_group_links: allStates.selectedValues.map(source => source.label),
      annotation: {
        annotation_name: "Document check-worthiness",
        params: {
          factual_1_from: allStates.factualityValue,
          harmful_1_from: allStates.harmfulValue
        }
      },
      entities: ["annotations"],
      order_by: allStates.postOrderBy,
      order_type: allStates.postOrderType,
      page: newPage
    })
      .then((response) => {
        setAllStates({
          ...allStates,
          'posts': response.data.contents,
          'postsPage': newPage
        })
      });
  };

  if (!allStates.posts) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderBar open={open} drawerWidth={DRAWER_WIDTH} handleDrawerOpen={handleDrawerOpen} />
        <DrawerComponent allStates={allStates} setAllStates={setAllStates} drawerWidth={DRAWER_WIDTH} handleDrawerClose={handleDrawerClose} open={open} theme={theme} />
        <Main open={open}>
          <DrawerHeader />
          <FilteringPostsComponent allStates={allStates} setAllStates={setAllStates} />
          <div className='mt-4 mb-4'>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </div>
        </Main>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderBar open={open} drawerWidth={DRAWER_WIDTH} handleDrawerOpen={handleDrawerOpen} />
      <DrawerComponent allStates={allStates} setAllStates={setAllStates} drawerWidth={DRAWER_WIDTH} handleDrawerClose={handleDrawerClose} open={open} theme={theme} />
      <Main open={open}>
        <DrawerHeader />
        <FilteringPostsComponent allStates={allStates} setAllStates={setAllStates} />
        {/* TODO: minWidth of graph ensures some kind of responsivness on mobiles, but that's definitely not a way to go*/}
        <ResponsiveContainer height={500} width="99%" minWidth={"600px"}>
          <LineChart
            height={500} data={allStates.postStatistics}
            margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" angle={-45} textAnchor="end" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Line name="Checkworthy" type="monotone" dataKey="checkworthiness_count" stroke="#ff0004" />
            <Line name="Harmful" type="monotone" dataKey="harmful_1_count" stroke="#14c0eb" />
            <Line name="Factual" type="monotone" dataKey="factual_1_count" stroke="#eb14e4" />
            <Line name="Total" type="monotone" dataKey="total_count" stroke="#00ff1a" />
          </LineChart>
        </ResponsiveContainer>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <b>Found {allStates.totalPosts} posts:</b>
          </Grid>
          <Grid item>
            <FormLabel className='justify-content-end' id="select-field-demo-label" htmlFor="select-field-demo-button" sx={{ fontSize: 12 }}>
              Sort by:
              <Select onChange={handleSort} value={allStates.postOrderBy + "-" + allStates.postOrderType} sx={{ width: 150 }}>
                <Option value="orig_created_at-desc">Date (newest)</Option>
                <Option value="orig_created_at-asc">Date (oldest)</Option>
                <Option value="views-desc">Views (most popular)</Option>
                <Option value="views-asc">Views (least popular)</Option>
                <Option value="total_interactions-desc">Total interactions (highest)</Option>
                <Option value="total_interactions-asc">Total interactions (lowest)</Option>
                <Option value="factual_1-desc">Factuality (highest)</Option>
                <Option value="factual_1-asc">Factuality (lowest)</Option>
                <Option value="harmful_1-desc">Harmfulness (highest)</Option>
                <Option value="harmful_1-asc">Harmfulness (lowest)</Option>
              </Select>
            </FormLabel>
          </Grid>
        </Grid>
        <TableContainer sx={{ mt: 3, mb: 3 }}>
          <Table>
            <TableRow>
              <StyledContentTableCell sx={{ width: '3%' }}><b>Link</b></StyledContentTableCell>
              <StyledContentTableCell><b>Channel</b></StyledContentTableCell>
              <StyledContentTableCell sx={{ width: '40%' }}><b>Post</b></StyledContentTableCell>
              <StyledContentTableCell align="right"><b>Forwards</b></StyledContentTableCell>
              <StyledContentTableCell align="right"><b>Interactions</b></StyledContentTableCell>
              <StyledContentTableCell align="right"><b>Views</b></StyledContentTableCell>
              <StyledContentTableCell align="right"><b>Replies</b></StyledContentTableCell>
              <StyledContentTableCell align="right"><b>Reactions</b></StyledContentTableCell>
              <StyledContentTableCell sx={{ width: '6%' }} align="right"><b>Factuality</b></StyledContentTableCell>
              <StyledContentTableCell sx={{ width: '6%' }} align="right"><b>Harmfulness</b></StyledContentTableCell>
              <StyledContentTableCell align="right"><b>Date</b></StyledContentTableCell>
            </TableRow>
            <TableBody>
              {allStates.posts.map((post, index) => (
                <TableRow key={post.link}>
                  <StyledContentTableCell sx={{ width: '3%' }}> {<ElementIconLink component={LinkIcon} style={{ cursor: 'pointer' }} onClick={event => window.open(post.link, '_blank')} />} </StyledContentTableCell>
                  <StyledContentTableCell>{JSON.parse(localStorage.getItem("sources")).filter(source => post.link.includes(source.link))[0].title}</StyledContentTableCell>
                  
                  <PostBodyComponent body={post.body}/>
                  
                  <StyledContentTableCell align="right">{post.metrics.interactions.forwards}</StyledContentTableCell>
                  <StyledContentTableCell align="right">{post.metrics.total_interactions}</StyledContentTableCell>
                  <StyledContentTableCell align="right">{post.metrics.views}</StyledContentTableCell>
                  <StyledContentTableCell align="right" padding='10px'>{post.metrics.interactions.replies > 0 ? post.metrics.interactions.replies : 0}</StyledContentTableCell>
                  <StyledContentTableCell sx={{ padding: 5 }} align="right">
                    {/* TODO: depends how do we want to display reactions here */}
                    {post.metrics.interactions.reactions !== undefined ? post.metrics.interactions.reactions.map(reaction => (
                      <div className='col-auto'> {reaction.type} {reaction.count}</div>
                    )) : 0}
                    {/* <Stack justifyContent="end" direction="row">
                        {post.metrics.interactions.reactions !== undefined ? post.metrics.interactions.reactions.map(reaction => (
                          <div className='col-auto'> {reaction.type} {reaction.count}</div>
                        )) : 0}
                      </Stack> */}
                  </StyledContentTableCell>
                  <StyledContentTableCell align="right" sx={{ width: '6%' }}>
                    {post.annotations[0].value !== null ?
                      <Chip label={factualityBins[Math.trunc(post.annotations[0].value.scores['factual_1'] * 10) / 10].text}
                        color='primary'
                        style={{ backgroundColor: factualityBins[Math.trunc(post.annotations[0].value.scores['factual_1'] * 10) / 10].color }}
                      />
                      : "no annotations"}
                  </StyledContentTableCell>
                  <StyledContentTableCell align="right" sx={{ width: '6%' }}>
                    {post.annotations[0].value !== null ?
                      <Chip label={harmfulBins[Math.trunc(post.annotations[0].value.scores['harmful_1'] * 10) / 10].text}
                        color='primary'
                        style={{ backgroundColor: harmfulBins[Math.trunc(post.annotations[0].value.scores['harmful_1'] * 10) / 10].color }}
                      />
                      : "no annotations"}
                  </StyledContentTableCell>
                  <StyledContentTableCell align="right">{new Date(post.orig_created_at).toLocaleString()}</StyledContentTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack alignItems="center">
          <Pagination count={allStates.numberOfPostPages} page={allStates.postsPage} onChange={handleChangePage} />
        </Stack>
      </Main>
    </Box>
  );
}