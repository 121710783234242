import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useNavigate } from "react-router";
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import { SOURCES_FACTUALITY_LEVEL, SOURCES_HARMFULNESS_LEVEL} from "../utilities/consts"
import { isValidDate } from "../utilities/utilities"

export default function FilteringSourcesComponent({ allStates, setAllStates }) {
    const navigate = useNavigate();
    const handleChange = (values) => {
        setAllStates({...allStates, 
            'selectedValues': values
        })
    };

    // filtering Sources 
    const filterSources = (e) => {
        if (Object.keys(allStates.selectedValues).length) {
        setAllStates({...allStates, 'sources': null})
        const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : null;
        const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : null;
        axios.post(process.env.REACT_APP_API_GROUP_SOURCE_SEARCH, {
            and_filters: {
                link: allStates.selectedValues.map(selectedValue => selectedValue.label),
            },
            or_filters: {},
            content_orig_created_at_from: created_at_from ? created_at_from : null,
            content_orig_created_at_to: created_at_to ? created_at_to : null,
            content_body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
            checkworthiness_indicator: {
                // TODO: this has to be set to some threshold to be relevant
                factual_1: SOURCES_FACTUALITY_LEVEL,
                harmful_1: SOURCES_HARMFULNESS_LEVEL,
            },
            statistics: {
                name: "counts",
                select: ["link", "title"]
            },
            page: 1,
        })
            .then(response => {
                setAllStates({...allStates, 
                    'sources': response.data.sources,
                    'sourcesPage': 1,
                    'totalSources': response.data.pagination.total,
                    'numberOfSourcePages': response.data.pagination.pages,
                    'sourceOrderBy': 'total_content_count_period',
                    'sourceOrderType': 'desc',
                    'sourceStatistics': response.data.source_statistics
                })
            });
        }
    }

    // reset button
    const resetFilters = (e) => {
        window.location.reload();
        setAllStates({...allStates, 
            'bodyFilter': '',
            'startDate': null,
            'endDate': null,
            'selectedValues': JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.id, "label": x.link }))
        })
    }

    // show posts for selected filters
    const showPosts = (e) => {
        // TODO: change this when URL params will be implemented
        localStorage.setItem("loadInitPosts", false)
        setAllStates({...allStates, 'posts': null })
        navigate("/posts");
    }

    const customValueRenderer = (selected, _options) => {
        return selected.length <= 5 ? (
            selected.map(({ label, index }) => <div labelId={label} className="bg-primary d-inline mx-1 p-2">{label}</div>)
        ) : selected.length > 5 ? (
            <div>
                {selected.slice(0, 5).map(({ label, index }) => <div labelId={label} className="bg-primary d-inline mx-1 p-2" key={index}>{label}</div>)}
                {<div className="bg-primary d-inline mx-1 p-2">...</div>}
            </div>
        ) : null
      };

    if (allStates.sources)
    return (
        <div>
            <div className='row'>
                <MultiSelect
                    options={JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.link, "label": x.link }))}
                    value={allStates.selectedValues}
                    onChange={handleChange}
                    valueRenderer={customValueRenderer}
                />
            </div>
            <div className='row'>
                <div className="col-2 align-self-end mt-2">
                    <TextField value={allStates.bodyFilter} onChange={e => setAllStates({...allStates, 'bodyFilter': e.target.value})} fullWidth id="filled-basic" label="Filter on posts body" variant="filled" />
                </div>
                <div className="col-auto mt-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker value={allStates.startDate} clearable onChange={e => setAllStates({...allStates, 'startDate': e})} label="Posts published from"/>
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="col-auto mt-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker value={allStates.endDate} clearable onChange={e => setAllStates({...allStates, 'endDate': e})} label="Posts published to" />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="col-auto align-self-center justify-content-end mt-3">
                    <Button variant="contained" onClick={filterSources}>Filter</Button>
                </div>
                <div className="col-auto align-self-center justify-content-end mt-3">
                    <Button variant="contained" onClick={resetFilters}>Reset Filter</Button>
                </div>
                <div className="col-auto align-self-center justify-content-end mt-3">
                    <Button variant="contained" onClick={showPosts}>Show all posts for selected filters</Button>
                </div>
            </div>
        </div>
    );
}