import { useNavigate } from "react-router";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { setToken } from "./Auth";


// alerts
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function Login({ allStates, setAllStates,
    email, setEmail, password, setPassword }) {
    const navigate = useNavigate();

    // alerts
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
    const [loginFailedMessage, setLoginFailedMessage] = useState('');
    const [openFailedAlert, setFailedAlert] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setFailedAlert(false);
    };

    // login
    const login = async () => {
        if ((email === "") & (password === "")) {
            return;
        } else {
            // login request
            await axios
                .post(process.env.REACT_APP_API_LOGIN, {
                    email: email,
                    password: password,
                })
                .then(function (response) {
                    if (response.data.token) {
                        setToken(email, response.data.token, response.data.user_id);
                        axios
                        .get(process.env.REACT_APP_API_GET_USER_DATA + localStorage.getItem("user_id"))
                        .then(response => {
                          localStorage.setItem("sources", JSON.stringify(response.data.sources))
                          localStorage.setItem("loadInitPosts", true)
                          setAllStates({
                            ...allStates,
                            'selectedValues': JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.link, "label": x.link })),
                            'monitors': response.data.monitors,
                            'selectedPageIndex': 0,
                          })
                          return response
                        }).then( function (response) {
                            navigate("/sources")
                        })
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        setLoginFailedMessage(error.response.data.detail)
                        setFailedAlert(true)
                    }
                });
        }
    };
    return (
        <div>
            <div className="card-body p-5 text-center">
                <div className="mb-md-5 mt-md-4 pb-5">
                    <h2 className="mb-2 text-uppercase">Log in</h2>
                    <p className="mb-5">Please enter your login and password!</p>
                    <div className="form-outline mb-4">
                        <input type="email" className="form-control form-control-lg" onChange={(e) => setEmail(e.target.value)} />
                        <label className="form-label" htmlFor="typeEmailX">Email</label>
                    </div>
                    <div className="form-outline mb-4">
                        <input type="password" className="form-control form-control-lg" onChange={(e) => setPassword(e.target.value)} />
                        <label className="form-label">Password</label>
                    </div>
                    <button className="btn btn-outline-light btn-lg px-5 bg-secondary" type="submit" onClick={login} >Login</button>
                </div>
            </div>
            <Snackbar message="I love snacks" anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openFailedAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '300px' }}>
                    {loginFailedMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}