import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderBar from '../components/HeaderBar';
import DrawerComponent from '../components/DrawerComponent';
import Main from '../components/Main';
import { DRAWER_WIDTH } from '../utilities/consts';
import DrawerHeader from '../components/DrawerHeader';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

// pagination
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


export default function Monitors({
    allStates, setAllStates,
    theme, open, setOpen, handleDrawerClose, handleDrawerOpen
    }) {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/monitors/create");
    }
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [monitorsCopy, setMonitorsCopy] = useState([...allStates.monitors.slice(0, rowsPerPage)]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    useEffect(() => {
        const handlePagination = () => {
          setMonitorsCopy(allStates.monitors.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage))
        }
        handlePagination();
    }, [page]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <HeaderBar open={open} drawerWidth={DRAWER_WIDTH} handleDrawerOpen={handleDrawerOpen} />
            <DrawerComponent
            allStates={allStates} setAllStates={setAllStates} drawerWidth={DRAWER_WIDTH} handleDrawerClose={handleDrawerClose} open={open} theme={theme}/>
            <Main open={open}>
                <DrawerHeader />
                <Button className='bg-primary' onClick={() => handleClick()}>Create new monitor</Button>
                <ul className='list-group mb-4 mt-4'>
                {allStates.monitors.map(monitor => (
                    <li key={monitor.id} className='list-group-item mb-2'>
                    <div className='row'>
                        {/* TODO: remove slice below after BE changes */}
                        monitor: {monitor.name} frequency: {monitor.frequency} created_at: {monitor.created_at.slice(0,10)}
                    </div>
                    </li>
                ))}
                <Stack alignItems="center">
                    <Pagination count={Math.ceil(allStates.monitors.length / rowsPerPage)} page={page} onChange={handleChangePage} />
                </Stack>
                </ul>
            </Main>
        </Box>
    );
}