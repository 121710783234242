import React, { useEffect } from 'react';
import HeaderBar from '../components/HeaderBar';
import DrawerComponent from '../components/DrawerComponent';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { SOURCES_FACTUALITY_LEVEL, SOURCES_HARMFULNESS_LEVEL } from '../utilities/consts';
import { StyledContentTableCell, isValidDate } from "../utilities/utilities"
import Main from '../components/Main';
import DrawerHeader from '../components/DrawerHeader';
import FilteringSourcesComponent from '../components/FilteringSourcesComponent';
import axios from 'axios';
import dayjs from 'dayjs';


// sources formatting
import Table from '@mui/joy/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { ElementIconLink } from '../utilities/utilities'
import LinkIcon from '@mui/icons-material/Link';

// pagination
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// charts
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// fetching data 
import CircularProgress from "@mui/material/CircularProgress";

// sort button
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormLabel from '@mui/joy/FormLabel';

export default function Sources({ allStates, setAllStates, theme, open, setOpen, handleDrawerClose, handleDrawerOpen }) {

  // on refresh
  useEffect(() => {
    setAllStates({...allStates, 'sources': null})
    const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : null;
    const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : null;
    axios.post(process.env.REACT_APP_API_GROUP_SOURCE_SEARCH, {
      and_filters: {
        link: JSON.parse(localStorage.getItem("sources")).map((source) => (source.link)),
      },
      or_filters: {},
      content_orig_created_at_from: created_at_from ? created_at_from : null,
      content_orig_created_at_to: created_at_to ? created_at_to : null,
      content_body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
      checkworthiness_indicator: {
        // TODO: this has to be set to some threshold to be relevant
        factual_1: SOURCES_FACTUALITY_LEVEL,
        harmful_1: SOURCES_HARMFULNESS_LEVEL,
      },
      statistics: {
          name: "counts",
          select: ["link", "title"]
      },
      page: 1
    })
      .then(second_response => {
        setAllStates({
          ...allStates,
          'selectedValues': JSON.parse(localStorage.getItem("sources")).map((x) => ({ "value": x.link, "label": x.link })),
          'sourceStatistics': second_response.data.source_statistics,
          'sources': second_response.data.sources,
          'totalSources': second_response.data.pagination.total,
          'numberOfSourcePages': second_response.data.pagination.pages,
          'selectedPageIndex': 0
        })
      });
  }, []);

  // handle data sort
  const handleSort = (event, newValue) => {
    setAllStates({...allStates, 'sources': null })
    const [orderBy, orderType] = newValue.split("-")
    const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : null;
    const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : null;
    axios.post(process.env.REACT_APP_API_GROUP_SOURCE_SEARCH, {
      and_filters: {
        link: allStates.selectedValues.map(selectedValue => selectedValue.label),
      },
      or_filters: {
      },
      content_orig_created_at_from: created_at_from ? created_at_from : null,
      content_orig_created_at_to: created_at_to ? created_at_to : null,
      content_body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
      checkworthiness_indicator: {
        // TODO: do we filter this?
        factual_1: SOURCES_FACTUALITY_LEVEL,
        harmful_1: SOURCES_HARMFULNESS_LEVEL
      },
      statistics: {
        name: "counts",
        select: ["link", "title"]
      },
      order_by: orderBy,
      order_type: orderType,
      page: 1,
    })
      .then(response => {
        setAllStates({
          ...allStates,
          'sources': response.data.sources,
          'sourcesPage': 1,
          'sourceOrderBy': orderBy,
          'sourceOrderType': orderType
        })
      });
  };

  /* TODO: move this to some helper file? */
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light px-4">
          <p>{label}</p>
          <p>Number of checkworthy posts: {payload[0].value} {'('}out of {allStates.sourceStatistics.filter(source => source.title === label).map(source => source.total_content_count)}{')'}</p>
          <p>Number of non-checkworthy posts: {payload[1].value} {'('}out of {allStates.sourceStatistics.filter(source => source.title === label).map(source => source.total_content_count)}{')'}</p>
          <p>All posts: {payload[0].value + payload[1].value} {'('}out of {allStates.sourceStatistics.filter(source => source.title === label).map(source => source.total_content_count)}{')'}</p>
        </div>
      );
    }
    return null;
  };

  const handleChangePage = (event, newPage) => {
    setAllStates({...allStates, 'sources': null })
    const created_at_from = isValidDate(allStates.startDate) === true ? dayjs(new Date(allStates.startDate)).format("YYYY-MM-DD HH:mm:ss") : '';
    const created_at_to = isValidDate(allStates.endDate) === true ? dayjs(new Date(allStates.endDate)).format("YYYY-MM-DD HH:mm:ss") : '';
    axios.post(process.env.REACT_APP_API_GROUP_SOURCE_SEARCH, {
      and_filters: {
        link: allStates.selectedValues.map(selectedValue => selectedValue.label)
      },
      or_filters: {
      },
      content_orig_created_at_from: created_at_from ? created_at_from : null,
      content_orig_created_at_to: created_at_to ? created_at_to : null,
      content_body_keywords: allStates.bodyFilter ? allStates.bodyFilter.split(" ") : null,
      checkworthiness_indicator: {
          // TODO: this has to be set to some threshold to be relevant
          factual_1: SOURCES_FACTUALITY_LEVEL,
          harmful_1: SOURCES_HARMFULNESS_LEVEL,
      },
      statistics: {
          name: "counts",
          select: ["link", "title"]
      },
      order_by: allStates.sourceOrderBy,
      order_type: allStates.sourceOrderType,
      page: newPage,
    })
      .then(response => {
        setAllStates({
          ...allStates,
          'sources': response.data.sources,
          'sourcesPage': newPage
        })
      });
  };

  if (!allStates.sources) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderBar open={open} handleDrawerOpen={handleDrawerOpen} />
        <DrawerComponent allStates={allStates} setAllStates={setAllStates} handleDrawerClose={handleDrawerClose} open={open} theme={theme} />
        <Main open={open}>
          <DrawerHeader />
          <FilteringSourcesComponent allStates={allStates} setAllStates={setAllStates} />
          <div className='mt-4 mb-4'>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </div>
        </Main>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <DrawerComponent allStates={allStates} setAllStates={setAllStates} handleDrawerClose={handleDrawerClose} open={open} theme={theme} />
      <Main open={open}>
        <DrawerHeader />
        <FilteringSourcesComponent allStates={allStates} setAllStates={setAllStates} />
        {/* TODO: minWidth of graph ensures some kind of responsivness on mobiles, but that's definitely not a way to go*/}
        <ResponsiveContainer height={500} width="99%" minWidth={"600px"}>
          <BarChart data={allStates.sourceStatistics} margin={{ top: 20, right: 70, left: 20, bottom: 100 }}>
            <CartesianGrid />
            <XAxis dataKey="title" angle={-45} textAnchor="end" />
            <YAxis width={125} label={{ value: 'Number of posts', angle: -90, position: 'Left' }} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" height={36} />
            <Bar maxBarSize={50} name="Checkworthy posts" dataKey="checkworthy_content_count_period" stackId="a" fill="dark">
            </Bar>
            <Bar maxBarSize={50} name="Non checkworthy posts" dataKey="non_checkworthy_content_count_period" stackId="a" fill="green" />
          </BarChart>
        </ResponsiveContainer>
        <ul className='list-group mb-4 mt-4'>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <b>Found {allStates.totalSources} sources:</b>
            </Grid>
            <Grid item>
              <FormLabel className='justify-content-end' id="select-field-demo-label" htmlFor="select-field-demo-button" sx={{ fontSize: 12 }}>
              Sort by: 
              <Select onChange={handleSort} value={allStates.sourceOrderBy+"-"+allStates.sourceOrderType} sx={{ width: 150 }}>
                <Option value="total_content_count_period-desc">Posts count (highest)</Option>
                <Option value="total_content_count_period-asc">Posts count (lowest)</Option>
                <Option value="participants_count-desc">Participants (most)</Option>
                <Option value="participants_count-asc">Participants (least)</Option>
                <Option value="checkworthy_content_count_period-desc">Checkworthy posts (highest)</Option>
                <Option value="checkworthy_content_count_period-asc">Checkworthy posts (lowest)</Option>
                <Option value="orig_created_at-desc">Created at (newest)</Option>
                <Option value="orig_created_at-asc">Created at (oldest)</Option>
              </Select>
              </FormLabel>
            </Grid>
          </Grid>
          <TableContainer sx={{mt: 3, mb: 3}}>
            <Table>
                <TableRow>
                  <StyledContentTableCell style={{ width: '3%' }}><b>Link</b></StyledContentTableCell>
                  <StyledContentTableCell><b>Title</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Participants</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Photos</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Messages</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Videos</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Checkworthy posts</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Non checkworthy posts</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Posts count</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Last post</b></StyledContentTableCell>
                  <StyledContentTableCell align="right"><b>Created at</b></StyledContentTableCell>
                </TableRow>
              <TableBody>
                {allStates.sources.map((source) => (
                  <TableRow key={source.link}>
                    <StyledContentTableCell style={{ width: '3%' }}> {<ElementIconLink component={LinkIcon} style={{ cursor: 'pointer' }} onClick={event => {window.open(source.link, '_blank')}} />} </StyledContentTableCell>
                    <StyledContentTableCell>{source.title}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.metrics.participants_count !== null ? source.metrics.participants_count : 0}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.metrics.photos}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.metrics.total_messages}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.metrics.videos}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.checkworthy_content_count_period !== null ? source.checkworthy_content_count_period : 0}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.total_content_count_period - source.checkworthy_content_count_period}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.total_content_count_period}</StyledContentTableCell>
                    {/* TODO: Ideally prepare data on the backend, to not slice here */}
                    <StyledContentTableCell align="right">{source.latest_content_date.slice(0, 10)}</StyledContentTableCell>
                    <StyledContentTableCell align="right">{source.orig_created_at.slice(0, 10)}</StyledContentTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="center">
            <Pagination count={allStates.numberOfSourcePages} page={allStates.sourcesPage} onChange={handleChangePage} />
          </Stack>
        </ul>
      </Main>
    </Box>
  );
}
