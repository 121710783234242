import { Routes, Route } from "react-router-dom";
import React, { useState } from 'react';
import LoginPage from "./pages/LoginPage";
import Monitors from "./pages/Monitors";
import Sources from "./pages/Sources";
import Posts from "./pages/Posts";
import { RequireToken } from "./components/Auth";
import { useTheme } from '@mui/material/styles';
import CreateMonitor from "./pages/CreateMonitor";
import { SOURCES_FACTUALITY_LEVEL, SOURCES_HARMFULNESS_LEVEL } from "./utilities/consts"

function App() {
  const theme = useTheme();
  const [allStates, setAllStates] = useState({
    // UI states
    open: true,
    selectedPageIndex: 0,
    // data states
    isDataLoaded: false,
    posts: null,
    totalPosts: 0,
    postsPage: 1,
    sources: null,
    totalSources: 0,
    sourcesPage: 1,
    numberOfPostPages: 0,
    numberOfSourcePages: 0,
    monitors: [],
    // filters
    startDate: null,
    endDate: null,
    bodyFilter: null,
    selectedValues: [],
    factualityValue: SOURCES_FACTUALITY_LEVEL,
    harmfulValue: SOURCES_HARMFULNESS_LEVEL,
    // sort states
    sourceOrderBy: "total_content_count_period",
    sourceOrderType: "desc",
    postOrderBy: "orig_created_at",
    postOrderType: "desc",
    // graph visualisations
    postStatistics: [],
    sourceStatistics: [],
    // TODO: change this, when new data providers will be available
    availableProviders: "Telegram"
  })
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <LoginPage allStates={allStates} setAllStates={setAllStates} />
        } />
        <Route path="/monitors" element={
          <RequireToken>
            <Monitors allStates={allStates} setAllStates={setAllStates} theme={theme} open={open} setOpen={setOpen}
              handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
          </RequireToken>
        }
        />
        <Route path="/monitors/create" element={
          <RequireToken>
            <CreateMonitor allStates={allStates} setAllStates={setAllStates}
              theme={theme} open={open} setOpen={setOpen} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
          </RequireToken>
        }
        />
        <Route path="/sources" element={
          <RequireToken>
            <Sources allStates={allStates} setAllStates={setAllStates} theme={theme} open={open} setOpen={setOpen}
              handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
          </RequireToken>
        }
        />
        <Route path="/posts" element={
          <RequireToken>
            <Posts allStates={allStates} setAllStates={setAllStates}
              theme={theme} open={open} setOpen={setOpen} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
          </RequireToken>
        }
        />
      </Routes>
    </div>
  );
}

export default App;
