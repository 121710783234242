import MonitorIcon from '@mui/icons-material/Monitor';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DrawerHeader from './DrawerHeader';
import React from 'react';
import { useNavigate } from "react-router";
import { DRAWER_WIDTH } from '../utilities/consts';

export default function DrawerComponent({ allStates, setAllStates, handleDrawerClose, open, theme }) {
    const highlightPageButton = (value) => ({ 
        selected: allStates.selectedPageIndex === value
    });

    const navigate = useNavigate();
    const handleSources = (e) => {
        e.preventDefault();
        navigate("/sources");
    };
    const handlePosts = (e) => {
        e.preventDefault();
        navigate("/posts");
    };
    const handleMonitors = (e) => {
        e.preventDefault();
        setAllStates({...allStates, 'selectedPageIndex': 2})
        navigate("/monitors");
    };
    return (
        <Drawer
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem onClick={handleSources} key={"Sources"} disablePadding>
                    <ListItemButton {...highlightPageButton(0)}>
                        <ListItemIcon>
                            <MonitorIcon />
                        </ListItemIcon>
                        Sources
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={handlePosts} key={"Posts"} disablePadding>
                    <ListItemButton {...highlightPageButton(1)}>
                        <ListItemIcon>
                            <MonitorIcon />
                        </ListItemIcon>
                        Posts
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Drawer>
    );
}
