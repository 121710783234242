import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderBar from '../components/HeaderBar';
import DrawerComponent from '../components/DrawerComponent';
import Main from '../components/Main';
import DrawerHeader from '../components/DrawerHeader';
import { DRAWER_WIDTH } from '../utilities/consts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from '@mui/material/InputAdornment';
import HoverInfo from '../components/HoverInfo';
import axios from "axios";
// alerts
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

export default function CreateMonitor({ allStates, setAllStates,
    theme, open, setOpen, handleDrawerClose, handleDrawerOpen }) {
    const ref = React.createRef();
    const [monitorCreationFailedMessage, setMonitorCreationFailedMessage] = useState('');
    const [urls, setURLS] = useState('');
    const [provider, setProvider] = useState('');
    const [name, setName] = useState('');
    const [createdBy, setCreatedBy] = useState(localStorage.getItem("user_id"));

    const [openSuccessfulAlert, setSuccessfulAlert] = useState(false);
    const [openFailedAlert, setFailedAlert] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setSuccessfulAlert(false);
        setFailedAlert(false);
    };

    const handleProviders = (event) => {
        setProvider(event.target.value);
    };
    if(allStates.availableProviders.length === 0) {
        return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
        </Box>  
        );
    }
    const handleSubmitMonitor = (event) => {
        const url_values = urls.split(',').filter(e => e);
        // TODO: disabled until we handle telegram SIM cards
        // axios
        //     .post(process.env.REACT_APP_API_POST_MONITOR, {
        //     name: name,
        //     dag_id: name,
        //     values: url_values,
        //     created_by: createdBy,
        //     })
        //     .then(function (response) {
        //     console.log(response)
        //     if (response.data.status_code === 201) {
        //         setSuccessfulAlert(true)
        //          // TODO: add links from monitor to localStorage? 
        //     }
        //     })
        //     .catch(function (error) {
        //         setMonitorCreationFailedMessage(error.response.data.detail)
        //         setFailedAlert(true)
        //     });
    };
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <HeaderBar open={open} drawerWidth={DRAWER_WIDTH} handleDrawerOpen={handleDrawerOpen} />
            <DrawerComponent allStates={allStates} setAllStates={setAllStates} drawerWidth={DRAWER_WIDTH} handleDrawerClose={handleDrawerClose} open={open} theme={theme}/>
            <Main open={open}>
                <DrawerHeader />
                <div className='row'>
                    <div className='col-2 align-self-center mt-2 mb-2'>
                        Name
                    </div>
                    <div className='col-auto align-self-center'>
                        {<HoverInfo ref={ref} title={"Name for the monitor being created."}/>}
                    </div>
                    <div className='col-9 mt-2 mb-2'>
                        <TextField InputProps={{startAdornment: <InputAdornment position="start">{localStorage.getItem("username").split('@')[0]}_</InputAdornment>}} sx={{ width: 300 }} onChange={e => setName(localStorage.getItem("username").split('@')[0]+'_'+e.target.value)} id="outlined-basic" variant="outlined" />
                    </div>
                    <div className='col-2 align-self-center mt-2 mb-2'>
                        Social Media Platform
                    </div>
                    <div className='col-auto align-self-center'>
                        {<HoverInfo ref={ref} title={"Social media platform, to which source belongs."}/>}
                    </div>
                    <div className='col-9 mt-2 mb-2'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl sx={{ width: 300 }}>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={provider} onChange={handleProviders}>
                                    <MenuItem value={allStates.availableProviders}>{allStates.availableProviders}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='col-2 align-self-center mt-2 mb-2'>
                        <div>Channel and public group URLs</div>
                    </div>
                    <div className='col-auto align-self-center'>
                        {<HoverInfo ref={ref} title={"Channel/group links, which have to be separated by comma."}/>}
                    </div>
                    <div className='col-9 mt-2 mb-2'>
                        <TextField onChange={e => {setURLS(e.target.value)}} sx={{ width: 600 }} id="outlined-basic" variant="outlined" />
                    </div>
                    <div className='col-4 align-self-center'></div>
                    <div className='col-8'>
                        <Button className='bg-primary' onClick={handleSubmitMonitor}>Submit monitor</Button>
                    </div>
                    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openSuccessfulAlert} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '50%' }}>
                        Monitor created!
                        </Alert>
                    </Snackbar>
                    <Snackbar message="I love snacks" anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openFailedAlert} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '50%' }}>
                            {monitorCreationFailedMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </Main>
        </Box>
    );
}